<template>
  <div class>
    <Form
      :formItems="formItem"
      :tip="tips"
      :button="button"
      @submit="submit"
      @getCode="getCode"
      @nextSteep="nextSteep"
    ></Form>
    <dialog-box
      :title="title"
      :content="content"
      :showDialog="showDialog"
      @cancel="cancel"
      @confirm="confirm"
      :buttons="dialogButtons"
    ></dialog-box>
  </div>
</template>
<script>
import { computed, reactive, toRefs, inject } from "vue";
import {
  resetPasswordNotLogin,
  getPWAuthorCode,
  testPWAuthorCode
} from "../../utils/api";
import Form from "../../components/form.vue";
import {
  getQueryStr,
  inputIsAllFill,
  jumpTo,
  sleep,
  toast,
  checkPasswordFn
} from "../../utils/util";
export default {
  name: "",
  components: { Form },
  setup() {
    const state = reactive({
      formItems: [
        [
          { label: "手机号码", value: "", type: "input" },
          {
            label: "验证码",
            value: "",
            type: "input",
            eventName: "getCode",
            eventLabel: "获取验证码"
          }
        ],
        [
          { label: "卡号", value: "", type: "input" },
          {
            label: "设置密码",
            value: "",
            textType: "password",
            tip: "请输入新密码",
            type: "input"
          },
          {
            label: "确认密码",
            value: "",
            textType: "password",
            tip: "请再次输入新密码",
            type: "input"
          }
        ]
      ],
      buttons: [
        { label: "下一步", eventName: "nextSteep" },
        { label: "提交", eventName: "submit" }
      ],
      //  tips: '提示：密码修改成功后，提现与登陆密码同时被修改',
      tips:
        "提示：登录密码应为8-16个字符,必须包含数字、大小写字母混合、特殊字符共4种",
      stepIndex: 0,
      disable: false,
      canWithdraw: false,
      isLogin: false,
      title: "操作确认",
      content: "密码修改成功后，提现与登陆密码同时被修改，请问是否确认修改？ ",
      dialogButtons: [
        { label: "取消", eventName: "cancel" },
        { label: "修改", eventName: "confirm" }
      ],
      showDialog: false
    });
    const loginStatus = inject("loginStatus");
    state.isLogin = loginStatus.state.isLogin;
    const formItem = computed(() => {
      return state.formItems[state.stepIndex];
    });
    const tip = computed(() => {
      return state.tips[state.stepIndex];
    });
    const button = computed(() => {
      return state.buttons[state.stepIndex];
    });

    const passwordType = getQueryStr("passwordType") || "FOUNDLOGINPWD"; //修改密码类型  默认为登录密码修改
    console.log(passwordType);
    state.formItems[1][0].value = getQueryStr("cardNo") || "";
    state.formItems[0][0].value = getQueryStr("phone") || "";
    async function init() {
      try {
        if (passwordType === "FOUNDLOGINPWD") {
          //登录密码修改
          document.title = "登录密码修改";
          state.content = "确定修改登录密码？";
        } else if (passwordType === "FOUNDPHONEPWD") {
          //投注密码修改
          document.title = "投注密码修改";
          state.tips = "提示：投注密码修改后，请务必牢记，否则无法进行投注。";
          state.content = "确定修改投注密码？";
        } else if (passwordType === "FOUNDACCOUNTPWD") {
          //提现密码修改
          document.title = "提现密码修改";
          state.tips = "提示：提现密码修改后，请务必牢记，否则无法进行提现。";
          state.content = "确定修改提现密码？";
        }
        //判断用户是否登录 是否能充值 显示不同的tips
        // const withdraw = await canWithdraw()
        // state.tips = [`提示：密码修改成功后，${withdraw ? '提现、' : ''}登录和投注密码同时被修改`]
      } catch (e) {
        console.error(e);
      }
    }
    init();

    async function nextSteep() {
      try {
        const items = state.formItems[0];
        if (inputIsAllFill(items)) {
          const params = {
            mobile: items[0].value,
            valid: items[1].value
          };
          const result = await testPWAuthorCode(params);
          if (result.isSuccess == true) {
            state.stepIndex = 1;
            state.formItems[1][0].value = result.returnValue;
            state.formItems[1][0].type = "read";
          } else {
            toast(result.message);
          }
        } else {
          toast("输入选项不能为空");
        }
      } catch (e) {
        console.error(e);
      }
    }
    async function submit() {
      try {
        const items = state.formItems[1];
        for (let i = 0, l = items.length; i < l; i++) {
          const ele = items[i];
          console.log(ele);
          if (!ele.value || ele.value.trim() == "") {
            toast(`${items[i].label}不能为空`);
            return;
          }
        }
        if (items[1].value.trim() != items[2].value.trim()) {
          toast("两次输入密码不同，请重新输入");
          return;
        }
        // -----校验密码强度  仅仅登录密码-----
        if (passwordType === "FOUNDLOGINPWD") {
          const { code, msg } = await checkPasswordFn(items[1].value.trim());
          if (code != 0) {
            return toast(msg);
          }
          const { code: code1, masg: msg1 } = await checkPasswordFn(
            items[1].value.trim(),
            false
          );
          if (code1 != 0) {
            return toast(msg1);
          }
        }
        // -----------
        state.showDialog = true;
      } catch (e) {
        console.log(e);
      }
    }
    async function getCode() {
      if (state.disable) {
        return;
      }
      try {
        const items = state.formItems[0];
        if (items[0].value) {
          const result = await getPWAuthorCode(items[0].value);
          if (result.code == "0000") {
            let i = 60;
            toast("验证码已发送");
            state.disable = true;
            console.log(999);
            while (i) {
              await sleep(1000);
              items[1].eventLabel = `${i}秒`;
              i--;
            }
            items[1].eventLabel = "获取验证码";
            state.disable = true;
          }
        } else {
          toast("手机号码不能为空");
        }
      } catch (e) {
        console.error(e);
      }
    }
    function cancel() {
      state.showDialog = false;
    }
    async function confirm() {
      try {
        const items = formItem.value;
        const params = {
          betCardNO: items[0].value,
          newPassword: items[1].value,
          type: passwordType
        };
        state.showDialog = false;
        const result = await resetPasswordNotLogin(params);
        if (result.code == "0000") {
          toast("修改密码成功");
          await sleep(1000);
          jumpTo("/");
        }
      } catch (e) {
        console.error(e);
      }
    }
    return {
      ...toRefs(state),
      formItem,
      tip,
      button,
      nextSteep,
      submit,
      getCode,
      cancel,
      confirm
    };
  }
};
</script>
<style lang='scss' scoped>
::v-deep .dialog_content {
  text-align: center;
}
</style>